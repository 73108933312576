<template>
	<div
		class="row mx-2 my-3">
		<div
			v-if="countriesLoading"
			class="d-flex justify-content-center align-items-center"
			style="width: 100%;">
			<is-loading :loading="countriesLoading" />
		</div>
		<template v-if="!countriesLoading">
			<div
				id="countries-item-select"
				class="col-xs-12 col-sm-12 col-md-10">
				<label
					for="countries"
					class="label"
					style="float: left;">
					{{ translate('country') }}
					<span class="text-danger">*</span>
				</label>
				<item-select
					id="countries"
					class="pt-2"
					:placeholder="translate('select_country')"
					:options="allCountries"
					:initial-value="selectedCountry"
					:selected-item.sync="countryItem" />
				<p
					class="mx-1 pt-0 text-muted small">
					*{{ translate('country_message') }}
				</p>
			</div>
			<div
				class="col-xs-12 col-sm-12 col-md-2 text-right"
				:class="!['xs', 'sm'].includes(windowWidth) ? 'pt-4 mt-1' : ''">
				<b-button
					style="border-width: thick; width: inherit;"
					:disabled="countryItem == null"
					variant="primary"
					@click="selectCountry">
					{{ translate('select') }}
				</b-button>
			</div>
		</template>
	</div>
</template>
<script>
import ItemSelect from '@/components/ItemSelect';
import WindowSizes from '@/mixins/WindowSizes';
import {
	Countries,
	GiftOrders,
} from '@/translations';
import Country from '@/util/Country';

export default {
	name: 'CountryEdit',
	messages: [Countries, GiftOrders],
	components: {
		ItemSelect,
	},
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		selectedCountry: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			countries: new Country(),
			countryItem: null,
		};
	},
	computed: {
		countriesLoading() {
			return !!this.countries.data.loading;
		},
		selectedCountryItem() {
			return this.allCountries.find((item) => item.attributes.code === this.selectedCountry);
		},
		allCountries() {
			try {
				return this.countries.data.response.data.data.map((item) => ({
					...item,
					value: item.attributes.code,
					text: this.translate(item.attributes.code.toLowerCase()),
				})).filter((item) => item.attributes.code !== 'CN');
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		selectedCountryItem: {
			immediate: true,
			handler(selectedCountryItem) {
				this.countryItem = selectedCountryItem;
			},
		},
	},
	mounted() {
		this.getAllCountries();
	},
	methods: {
		getAllCountries() {
			this.countries.getCountries({ giftOrders: true }).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		selectCountry() {
			if (this.countryItem === null) {
				return;
			}
			this.$emit('country-selected', this.countryItem?.attributes?.code ?? null);
		},
	},
};
</script>

<template>
	<div class="mx-1 my-1 cart">
		<div class="row no-gutters w-100 justify-content-center">
			<div
				class="col"
				style="max-width: 800px;">
				<template>
					<div class="table-responsive m-0">
						<b-table
							id="cart-table"
							:busy="loading"
							:items="tableData"
							:fields="fields"
							:class="(windowWidth !== 'xs' ) ? 'text-nowrap' : ''"
							class="table-bottom-border table-cart"
							stacked="sm"
							striped
							small
							head-variant="blue-cart">
							<template v-slot:cell(product_name)="product">
								<img
									:src="product.item.product_image"
									class="responsive block-inline"
									style="max-width:40px">
							</template>
							<template v-slot:cell(product_code_name)="product">
								{{ product.item.product_code_name }}
								<br>
								<span
									v-if="product.item.replaceable"
									v-b-tooltip.hover
									:title="translate('pick_flavors')"
									class="badge badge-green pointer">
									{{ translate('replaceable') }}
									<i class="fas fa-info-circle" />
								</span>
							</template>
							<template
								v-slot:head(quantity)
								class="w-20">
								{{ translate('quantity') }}
							</template>
							<template v-slot:cell(quantity)="product">
								<div
									class="row"
									:class="{'py-1 px-4 mx-1' : ['xs'].includes(windowWidth)}">
									<div class="col px-2">
										{{ product.item.quantity }}
									</div>
								</div>
							</template>
							<div
								slot="table-busy"
								class="text-center white-space-normal">
								<is-loading
									:loading-label="translate('loading')"
									:no-data-label="translate('empty_cart')"
									:loading="loading"
									:has-data="productsHasData"
									:class="windowWidth === 'xs' && !productsHasData ? 'normal-whitespace' : ''" />
							</div>
						</b-table>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import {
	Cart as CartMessages,
	GiftOrders,
	Tooltip,
	Grids,
	Products,
} from '@/translations';

export default {
	name: 'ProductOverview',
	messages: [
		CartMessages,
		GiftOrders,
		Tooltip,
		Grids,
		Products,
	],
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		cartProducts: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		tableData() {
			const table = [];
			try {
				this.cartProducts.forEach((item) => {
					const productName = this.translate(item.code_name);
					const product = {
						id: item.item_id,
						quantity: item.qty,
						subtotal: item.sub_total,
						total_volume: item.total_volume,
						unit_price: item.unit_price,
						product_code_name: productName,
						product_image: item.image,
						sku: item.sku,
						changed: false,
						replaceable: item.replaceable,
					};

					product.show_input = false;
					product.show_select = true;

					if (product.quantity > 9) {
						product.show_select = false;
						product.show_input = true;
					}

					table.push(product);
				});
			} catch (error) {
				return table;
			}
			return table;
		},
		fields() {
			return [
				{
					key: 'product_name', label: ['xs'].includes(this.windowWidth) ? '' : this.translate('product_name'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'product_code_name', label: this.translate('name'), class: 'text-center align-middle white-space-normal', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'unit_price', label: this.translate('unit_price'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'quantity', label: this.translate('quantity'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'subtotal', label: this.translate('subtotal'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
			];
		},
		productsHasData() {
			return this.cartProducts.length > 0;
		},
	},
};
</script>
<style>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	.custom-btn.custom-white-btn{
		border: 1px solid white;
		width: 150px;
		border-radius: 25px;
		background: transparent;
		color: white;
	}
	.custom-btn.custom-white-btn:hover{
		background: white;
		color: black;
	}
	.custom-btn.custom-primary-btn{
		width: 150px;
		border-radius: 25px;
	}
	.table-cart {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
		border-collapse: collapse;
		width: 100%;
	}
	.table-cart {
		text-align: left;
	}
	.td-th-class {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}
	@media only screen and (max-width: 575px) {
		.responsive {
			width: 100%;
			max-height: 150px;
			max-width: 150px;
		}
	}
	.cart  {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
	html body div {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	p h5 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 400 !important;
	}
</style>

export const GIFT_ORDERS_DESCRIPTION_LIST = {
	// points: sub_points
	description_1: 0,
	description_2: 3,
	description_3: 0,
	description_4: 0,
	description_5: 0,
};

export const GIFT_ORDER_VALID_COUNTRIES = [
	'AR', 'AU', 'AT', 'BE', 'BG', 'CA', 'CO', 'HR', 'CY', 'CZ', 'DK', 'DO', 'EE', 'FI', 'FR', 'DE',
	'GR', 'HK', 'HU', 'IE', 'IT', 'JP', 'LV', 'LT', 'LU', 'MY', 'MT', 'MX', 'NL', 'NZ', 'PA', 'PG',
	'PY', 'PL', 'PT', 'RO', 'SG', 'SK', 'SI', 'ES', 'SE', 'GB', 'US', 'CH', 'BR',
	'GI', 'IS', 'LI', 'MC', 'NO', 'RS', 'IL',
];

export default {};

import Purchase from '@/util/Purchase';

export default {
	data() {
		return {
			validator: new Purchase(),
			preparing: false,
		};
	},
	computed: {
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
	},
	methods: {
		updateGiftOrderInfo(payload) {
			return this.validator.updateGiftOrderInformation(payload)
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		},
		placeGiftOrder(payload) {
			if (this.validating) {
				return null;
			}
			return this.validator.validate(payload, 'GiftOrder')
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
	},
};

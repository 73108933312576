<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="mx-2 mt-3">
		<template v-if="!loading">
			<div
				class="row no-gutters justify-content-center">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'pb-3' : 'px-2'"
					class="col-12">
					<div class="row mx-2 mt-1">
						<div class="col-12">
							<div
								v-for="(item, key) in configurableProducts"
								:key="item.attributes.sku"
								:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 pb-2 mb-2' : ''"
								class="row">
								<div class="col">
									<configurable-product
										:product="item.attributes"
										:code-name="item.attributes.code_name"
										:sku="item.attributes.sku"
										:config-name="getProductConfigKey(item)"
										:thumbnail="item.attributes.thumbnail"
										:stacked-layout="['xs', 'sm'].includes(windowWidth)">
										<div class="row">
											<div
												v-for="(config, configKey) in getProductConfigs(item)"
												:key="configKey"
												class="col-12 col-md-4">
												<product-configuration
													:configuration="getConfiguredProduct(item, configKey).code_name"
													:init-qty="0"
													:stacked-layout="['xs', 'sm'].includes(windowWidth)"
													@change="addToSelectedProducts(getConfiguredProduct(item, configKey).sku, $event)" />
											</div>
										</div>
									</configurable-product>
								</div>
							</div>
						</div>
						<div
							v-for="(item, key) in simpleProducts"
							:key="item.attributes.sku"
							class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-2"
							:class="{
								'pl-0': ['xs', 'sm'].includes(windowWidth),
								'pb-3': key === simpleProducts.length - 1,
							}">
							<div class="row no-gutters">
								<div class="col-12">
									<simple-product
										:code-name="item.attributes.code_name"
										:bvs="Number(item.attributes.bvs)"
										:init-qty="0"
										:price="item.attributes.price"
										:thumbnail="item.attributes.thumbnail"
										:stacked-layout="['xs', 'sm'].includes(windowWidth)"
										@change="addToSelectedProducts(item.attributes.sku, $event)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!!subtotal && !loading && !isPreviewOutdated"
				class="row no-gutters mb-2 px-2">
				<div
					class="col-12 d-flex"
					:class="['xs'].includes(windowWidth) ? 'justify-content-center' : 'justify-content-end'">
					<span :class="['xs'].includes(windowWidth) ? 'h3' : 'h2'">
						{{ translate('subtotal') }} {{ subtotal }}
					</span>
				</div>
			</div>
			<div class="row no-gutters px-2">
				<div class="col col-12 no-gutters justify-content-end text-right">
					<button
						:disabled="disableActions"
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-secondary btn-lg mr-2"
						:class="['xs'].includes(windowWidth) ? 'mb-2' : ';'"
						@click="previewTotals()">
						{{ translate('preview_total') }}
					</button>
					<button
						:disabled="disableActions"
						type="button"
						:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
						aria-label="Close"
						class="btn w-100 btn-primary btn-lg"
						@click="confirm()">
						{{ translate('continue') }}
					</button>
				</div>
			</div>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!allProducts.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { GiftOrders, Products, Purchase } from '@/translations';
import ConfigurableProduct from './ConfigurableProduct';
import ProductConfiguration from './ProductConfiguration';
import SimpleProduct from './SimpleProduct';

export default {
	name: 'ProductsEdit',
	messages: [GiftOrders, Products, Purchase],
	components: {
		ConfigurableProduct,
		IsLoading,
		ProductConfiguration,
		SimpleProduct,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		productsLoading: {
			type: Boolean,
			default: false,
		},
		subtotal: {
			type: String,
			default: '',
		},
		allProducts: {
			type: Array,
			default: () => ([]),
		},
		initialSelectedProducts: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectedProducts: {},
			disableActions: true,
			isPreviewOutdated: false,
		};
	},
	computed: {
		loading() {
			return this.productsLoading || !this.allProducts.length;
		},
		simpleProducts() {
			return this.allProducts.filter((product) => !product.attributes.has_configurations);
		},
		configurableProducts() {
			return this.allProducts.filter((product) => product.attributes.has_configurations);
		},
	},
	watch: {
		selectedProducts: {
			handler(newVal) {
				// Actions are only enabled if there are products with quantity
				this.disableActions = Object.values(newVal).reduce((acc, val) => acc + (val || 0), 0) === 0;

				// Preview is outdated if the selected products change after preview
				this.isPreviewOutdated = true;
			},
			deep: true,
		},
		initialSelectedProducts(newVal) {
			this.selectedProducts = window.structuredClone(newVal);
		},
	},
	methods: {
		confirm() {
			this.$emit('confirm', window.structuredClone(this.selectedProducts));
		},
		addToSelectedProducts(sku, qty) {
			this.$set(this.selectedProducts, sku, qty);
		},
		previewTotals() {
			this.isPreviewOutdated = false;
			this.$emit('preview-totals', window.structuredClone(this.selectedProducts));
		},
	},
};
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2 no-gutters">
		<div
			v-if="!paymentMethodsLoading"
			class="col">
			<div class="row">
				<div class="col-12">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item border-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="selectedPaymentMethodName = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
				</div>
			</div>
			<ul
				:class="{ 'mx-3': showSectionsAsCards }"
				class="list-group">
				<li
					v-if="showSectionsAsCards"
					class="list-group-item h-100 p-0">
					<div
						class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
						<div class="col my-auto align-middle">
							<div class="h5 m-0">
								{{ translate('cashondelivery') }}
							</div>
						</div>
					</div>
					<div class="px-4 py-2">
						<b-row>
							<b-col cols="12">
								<wallet-payment
									:show-title="false"
									:wallet-balance-loading="walletBalanceLoading"
									:balance="balance"
									:wallet-type="walletType"
									:wallet-type-errors="validationErrors['payment.payment_method.wallet_type']"
									:wallet-password-errors="validationErrors['payment.payment_method.wallet_password']"
									@passwordChanged="walletPassword = $event" />
							</b-col>
							<b-col cols="6">
								<label
									for="amount"
									class="label">
									{{ translate('amount_to_pay_with_payment_method', { method: translate('cashondelivery') }) }}
									<span class="text-danger">*</span>
								</label>
							</b-col>
						</b-row>
					</div>
				</li>
				<li
					:class="{ 'border-0': !showSectionsAsCards }"
					class="list-group-item h-100 p-0">
					<div
						v-if="showSectionsAsCards"
						class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
						<div class="col my-auto align-middle">
							<div class="h5 m-0">
								{{ translate(selectedPaymentMethodName) }}
							</div>
						</div>
					</div>
					<div class="pb-2">
						<div class="row">
							<div class="col-12">
								<ul class="list-group w-100">
									<li class="list-group-item rounded-0 border-0 pb-0">
										<credit-card-payment
											v-if="CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions"
											:card-id.sync="cardId"
											@addCreditCard="addCreditCard = $event"
											@loading="creditCardsLoading = $event" />
										<wallet-payment
											v-if="selectedPaymentMethodName === 'cashondelivery'"
											:show-title="paymentMethods.length === 1"
											:wallet-balance-loading="walletBalanceLoading"
											:balance="balance"
											:wallet-type="walletType"
											:wallet-type-errors="validationErrors['payment.payment_method.wallet_type']"
											:wallet-password-errors="validationErrors['payment.payment_method.wallet_password']"
											@passwordChanged="walletPassword = $event" />
										<bitcoin-payment
											v-if="selectedPaymentMethodName === 'banktransfer'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions" />
									</li>
								</ul>
							</div>
						</div>
						<!-- Billing Address -->
						<template v-if="!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName) && conditions.isAvailable">
							<div class="row">
								<div class="col-12">
									<ul class="list-group w-100">
										<li class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pt-0">
											<div class="row mt-2">
												<div class="col-12">
													<div class="h4 mb-0">
														{{ translate('billing_address') }}
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="px-2 pb-1">
								<div class="row px-1">
									<div class="col-12">
										<address-radio-button
											class="mw-100"
											:address-data="normalAddresses"
											:address-loading="addressLoading"
											:form-import="form"
											:hide-new-address="addAddress"
											:display-add-new-address="false"
											:borderless="true"
											button-group-name="billing-address"
											@add-address="addressFormState" />
										<div
											v-if="!addAddress && !addressLoading"
											class="row">
											<div class="col-12 px-4">
												<hr class="mt-0">
											</div>
										</div>
										<div
											v-if="addAddress && !addressLoading"
											class="pt-3 px-2">
											<address-country-form
												:user-country="true"
												:form-import="form"
												:errors-import="addressErrors.errors"
												:prefix-errors="''"
												:display-cancel-button="false"
												:display-submit-button="false"
												:display-goback-button="false"
												:display-override-toggle="showOverrideValidation" />
										</div>
									</div>
								</div>
								<div
									v-if="!addressLoading"
									class="row px-2">
									<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
										<button
											v-if="buttonStates.seeAddressList === 'enabled'"
											:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
											class="btn btn-link b-4 float-left"
											@click="addressFormState">
											{{ translate('see_address_list') }}
										</button>
										<button
											v-if="buttonStates.useAnotherAddress === 'enabled'"
											:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
											class="btn btn-link b-4 float-left"
											@click="addressFormState">
											{{ translate('use_another_address') }}
										</button>
									</div>
								</div>
								<div
									v-if="buttonStates.newAddressCancel !== 'hidden' || buttonStates.newAddressSave !== 'hidden'"
									class="row p-2">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
										class="col-12 col-md-auto">
										<div class="row no-gutters justify-content-end">
											<div
												:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
												class="col order-2 order-md-1">
												<b-button
													v-if="buttonStates.newAddressCancel !== 'hidden'"
													:disabled="buttonStates.newAddressCancel === 'disabled'"
													style="min-width: 150px"
													class="w-100"
													variant="light"
													size="lg"
													@click="addressFormState; $emit('cancel')">
													{{ translate('cancel') }}
												</b-button>
											</div>
											<div
												:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
												class="col col-md-6 order-1 order-md-2">
												<b-button
													v-if="buttonStates.newAddressSave !== 'hidden'"
													:disabled="buttonStates.newAddressSave === 'disabled'"
													class="w-100"
													style="min-width: 200px"
													variant="primary"
													size="lg"
													@click="newAddress(form)">
													{{ translate('continue') }}
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</li>
			</ul>
			<div
				v-if="buttonStates.mainCancel !== 'hidden' || buttonStates.mainSave !== 'hidden'"
				class="row px-3 pb-1">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
					class="col-12 col-md-auto">
					<div
						v-if="(typeof validationErrors['all.billing.address_id'] !== 'undefined')"
						class="row p-2">
						<div
							id="address"
							class="col-12">
							<b-alert
								variant="danger"
								show>
								<div
									v-for="error in validationErrors['all.billing.address_id']"
									:key="error">
									<span
										v-text="error" />
									<br>
								</div>
							</b-alert>
						</div>
					</div>
					<div class="row no-gutters justify-content-end">
						<div
							v-if="buttonStates.mainCancel !== 'hidden'"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
							class="col order-2 order-md-1">
							<b-button
								:disabled="validating || !!preparing || buttonStates.mainCancel === 'disabled' || !!systemLoading"
								style="min-width: 150px"
								class="w-100"
								variant="light"
								size="lg"
								@click="$emit('cancel')">
								{{ translate('cancel') }}
							</b-button>
						</div>
						<div
							v-if="buttonStates.mainSave !== 'hidden'"
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							class="col col-md-6 order-1 order-md-2">
							<b-button
								:disabled="validating || !!preparing || buttonStates.mainSave === 'disabled' || !conditions.isAvailable || !!systemLoading"
								style="min-width: 200px"
								class="w-100"
								variant="primary"
								size="lg"
								@click="prepareForStorage()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="paymentMethodsLoading"
			class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import {
	Addresses as AddressesMessages,
	Profile as ProfileMessages,
	Purchase as PurchaseMessages,
	PaymentMethods as PaymentMethodsMessages,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import CreditCard from '@/util/CreditCard';
import Wallets from '@/util/Wallets';
import CommonMix from '../../mixins/Common';
import Addresses from '../../mixins/Addresses';
import { currency } from '@/config/Filters';
import WalletPayment from '@/components/Payment/WalletPayment';
import BitcoinPayment from '@/components/Payment/BitcoinPayment';
import CreditCardPayment from '@/components/Payment/CreditCardPayment';
import { CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';

export default {
	name: 'PaymentEdit',
	messages: [AddressesMessages, ProfileMessages, PurchaseMessages, PaymentMethodsMessages],
	components: {
		AddressRadioButton,
		AddressCountryForm,
		WalletPayment,
		BitcoinPayment,
		CreditCardPayment,
	},
	filters: {
		currency,
	},
	mixins: [Addresses, CommonMix, WindowSizes],
	props: {
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		cartId: {
			type: [String, Number],
			default: '',
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		systemLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			createAddress: new AddressBook(),
			state: new Country(),
			paymentMethodData: new Cart(),
			creditCard: new CreditCard(),
			wallets: new Wallets(),
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: 0,
			},
			alert: new this.$Alert(),
			purchaseCreditCardsErrors: {},

			walletTypes: WALLET_TYPES,
			billingAddressId: 0,
			cardId: 0,
			creditCardsLoading: false,
			selectedPaymentMethodName: 0,
			walletPassword: '',
			walletType: WALLET_TYPES.commission,

			addCreditCard: false,
			addAddress: false,
			showOverrideValidation: false,
			CREDIT_CARD_FORM_METHODS,
		};
	},
	computed: {
		showSectionsAsCards() {
			return false;
		},
		paymentMethods() {
			try {
				const excludedPaymentMethods = ['checkmo', 'sezzle', 'oxxopay', 'gcash'];
				return this.paymentMethodData.data.response.data.data.filter((paymentMethod) => !excludedPaymentMethods.includes(paymentMethod.attributes.method_code_name));
			} catch (error) {
				return [];
			}
		},
		ignoreLimitsFor() {
			try {
				return this.paymentMethodData.data.response.data.meta.ignore_limits_for;
			} catch (e) {
				return [];
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.total_balance;
			} catch (error) {
				return {};
			}
		},
		paymentMethodsLoading() {
			return !!this.paymentMethodData.data.loading;
		},
		walletBalanceLoading() {
			return !!this.wallets.data.loading;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		stepValidation() {
			return this.getStepValidationByName('PurchasePayment');
		},
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
		buttonStates() {
			const {
				addAddress,
				selectedPaymentMethodName,
				addCreditCard,
				hasNormalAddressesData,
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
			} = this;

			const loadingAny = [
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
			].some((loading) => loading);

			const buttonStates = {
				mainSave: 'enabled', // can disable
				mainCancel: 'enabled', // can disable
				newAddressSave: 'enabled', // can disable
				newAddressCancel: 'enabled', // can disable
				seeAddressList: 'enabled',
				useAnotherAddress: 'enabled',
			};

			if (loadingAny) {
				Object.keys(buttonStates).forEach((key) => {
					buttonStates[key] = 'disabled';
				});
			}

			buttonStates.mainCancel = 'hidden';
			buttonStates.newAddressCancel = 'hidden';

			if (!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				if (addAddress) {
					buttonStates.mainCancel = 'hidden';
					buttonStates.mainSave = 'hidden';
					buttonStates.useAnotherAddress = 'hidden';
				} else {
					buttonStates.newAddressCancel = 'hidden';
					buttonStates.newAddressSave = 'hidden';
					buttonStates.seeAddressList = 'hidden';
				}

				if (!hasNormalAddressesData) {
					buttonStates.seeAddressList = 'hidden';
					buttonStates.newAddressCancel = 'hidden';
				}
			}

			if (CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				buttonStates.newAddressCancel = 'hidden';
				buttonStates.newAddressSave = 'hidden';
				buttonStates.seeAddressList = 'hidden';
				buttonStates.useAnotherAddress = 'hidden';

				if (addCreditCard) {
					buttonStates.mainCancel = 'hidden';
					buttonStates.mainSave = 'hidden';
				}
			}

			return buttonStates;
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		conditions() {
			let conditions = { ...(this.paymentMethodConditions[this.selectedPaymentMethodName] || { isAvailable: true }) };
			if (Object.keys(conditions).length > 0 && typeof conditions.max !== 'undefined' && this.showSectionsAsCards) {
				conditions = { ...conditions, isAvailable: this.remainingForOtherMethod <= conditions.max };
			}
			if (this.ignoreLimitsFor.includes(this.selectedPaymentMethodName)) {
				conditions = { ...conditions, isAvailable: true };
			}
			return conditions;
		},
		normalAddresses() {
			return this.addressData.filter((item) => !item.attributes.is_gift_address);
		},
	},
	watch: {
		'form.address_id': function addressId(newVal) {
			this.billingAddressId = newVal;
		},
		hasNormalAddressesData: {
			deep: true,
			handler(newVal) {
				// If there are no normal addresses, then we need to show the add new address.
				this.addAddress = !newVal;
			},
		},
		selectedPaymentMethodName() {
			this.addCreditCard = false;
		},
	},
	async mounted() {
		this.form.country_code = this.country;
		this.initializeFormValues();
		this.initializeBillingAddress();

		await this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry(), cart_id: this.$cookie.get('cart_id') });
		if (this.selectedPaymentMethodName === 0) {
			this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
		}
		if (this.paymentMethods.some((method) => method.attributes.method_code_name === 'cashondelivery')) {
			this.walletType = this.walletTypes.commission;
			this.wallets.getBalance();
		}
	},
	methods: {
		async prepareForStorage() {
			this.preparing = true;

			const paymentMethodName = this.selectedPaymentMethodName;
			const { billingAddressId } = this;
			const payload = { cart_id: this.cartId };

			if (CREDIT_CARD_FORM_METHODS.includes(paymentMethodName)) {
				payload.payment = {
					payment_method: {
						name: paymentMethodName,
						card_id: this.cardId,
					},
				};
			} else if (paymentMethodName === 'cashondelivery') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						wallet_password: this.walletPassword,
						wallet_type: this.walletType,
					},
				};
			} else if (['banktransfer', 'lptpaypal'].includes(paymentMethodName)) {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			}

			payload.payment.payment_method = { ...payload.payment.payment_method };

			this.updateGiftOrderInfo(payload).then(() => {
				if (CREDIT_CARD_FORM_METHODS.includes(payload.payment.payment_method.name)) {
					this.creditCard.getCreditCard(this.$user.details().id, payload.payment.payment_method.card_id).then((response) => {
						const cardAddress = response.attributes.address;
						payload.payment.billing = {
							...payload.payment.billing,
							city: cardAddress.city,
							country_id: cardAddress.country.code,
							firstname: cardAddress.first_name,
							lastname: cardAddress.last_name,
							postcode: cardAddress.postcode,
							region: {
								region: cardAddress.region.name,
								region_code: cardAddress.region.code,
								region_id: cardAddress.region.id,
							},
							street: [cardAddress.address, cardAddress.address2],
						};
						this.$emit('update:payment', payload);
					});
				} else {
					this.$emit('update:payment', payload);
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}
					if (typeof this.validationErrors['all.payment_method.token'] !== 'undefined') {
						this.purchaseCreditCardsErrors.number.errorMessages = this.validationErrors['all.payment_method.token'];
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.purchaseCreditCardsErrors[field] !== 'undefined' && this.purchaseCreditCardsErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
		// Billing Address
		initializeBillingAddress() {
			this.refreshAddressBook().then((response) => {
				if (!this.form.address_id && response.length) {
					this.form.address_id = response[0].id;
				}
			});
		},
		initializeFormValues() {
			this.form = {
				...this.form,
				first_name: this.$user.details().name,
				last_name: this.$user.details().last_name,
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: this.country,
				region_id: '',
			};
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}
			payload.address_type = 'billing';

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).then(() => this.prepareForStorage()).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.clearValidationError(true);
			this.addAddress = !this.addAddress;
		},
	},
};
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mx-4 my-3">
		<div class="col-12 d-flex p-0 h-100 align-items-center">
			<div class="h-100 mr-2">
				<img
					v-b-tooltip.hover
					:title="translate(selectedCountry.toLowerCase())"
					:src="flagSrc"
					:alt="translate(selectedCountry.toLowerCase())">
			</div>
			<span class="font-weight-bolder">{{ translate(selectedCountry.toLowerCase()) }}</span>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import {
	Countries,
	GiftOrders,
} from '@/translations';

export default {
	name: 'CountryOverview',
	messages: [Countries, GiftOrders],
	mixins: [WindowSizes],
	props: {
		selectedCountry: {
			type: String,
			default: '',
		},
	},
	computed: {
		flagSrc() {
			return this.getFlag(this.selectedCountry);
		},
	},
	watch: {},
	created() {},
	methods: {
		getFlag(src) {
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			try {
				return require(`@/assets/images/common/countries/select/${src}.png`);
			} catch (error) {
				return require('@/assets/images/common/countries/select/default.png');
			}
			/* eslint-enable global-require */
			/* eslint-enable import/no-dynamic-require */
		},
	},
};
</script>

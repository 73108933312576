export default {};

export const SHOW_SIGNATURES_BY_COUNTRY = ['US'];
export const SHIPPING_UPDATABLE_FIELDS = ['address', 'city', 'postcode', 'country_code', 'region_id'];

export const ADDRESS_VERIFICATION_COUNTRIES = ['US'];
export const PRIVATE_CARRIER_COUNTRIES = ['MY', 'SG'];

function getShippingMethodsFormat({
	shippingMethods,
	carrier = undefined,
	image = 'default.png',
	deliveryDays = {},
	shippingNotice = { default: 'shipping_notice_ca' },
}) {
	const formattedShippingMethods = {};
	// eslint-disable-next-line no-restricted-syntax
	for (const shippingMethod of shippingMethods) {
		formattedShippingMethods[shippingMethod] = {
			image,
			delivery_days: deliveryDays,
			shipping_notice: shippingNotice,
		};
		if (typeof carrier !== 'undefined') {
			formattedShippingMethods[shippingMethod].carrier = carrier;
		}
	}
	return formattedShippingMethods;
}

const europeShippingMethods = [
	'matrixrate_matrixrate_13', // HU
	'matrixrate_matrixrate_16', // AT
	'matrixrate_matrixrate_19', // BE
	'matrixrate_matrixrate_22', // BG
	'matrixrate_matrixrate_25', // HR
	'matrixrate_matrixrate_28', // CY
	'matrixrate_matrixrate_31', // CZ
	'matrixrate_matrixrate_34', // DK
	'matrixrate_matrixrate_37', // EE
	'matrixrate_matrixrate_40', // FI
	'matrixrate_matrixrate_43', // FR
	'matrixrate_matrixrate_46', // DE
	'matrixrate_matrixrate_49', // GR
	'matrixrate_matrixrate_52', // IE
	'matrixrate_matrixrate_55', // IT
	'matrixrate_matrixrate_58', // LV
	'matrixrate_matrixrate_61', // LT
	'matrixrate_matrixrate_64', // LU
	'matrixrate_matrixrate_67', // MT
	'matrixrate_matrixrate_70', // NL
	'matrixrate_matrixrate_73', // PL
	'matrixrate_matrixrate_76', // PT
	'matrixrate_matrixrate_79', // RO
	'matrixrate_matrixrate_85', // SK
	'matrixrate_matrixrate_88', // SI
	'matrixrate_matrixrate_91', // ES
	'matrixrate_matrixrate_94', // SE
	'matrixrate_matrixrate_162', // CH
];

const europeNewFlatShipping = [
	'matrixrate_matrixrate_987', // CH
	'matrixrate_matrixrate_1025', // SE
	'matrixrate_matrixrate_1026', // ES
	'matrixrate_matrixrate_1027', // SI
	'matrixrate_matrixrate_1028', // SK
	'matrixrate_matrixrate_1030', // RO
	'matrixrate_matrixrate_1031', // PT
	'matrixrate_matrixrate_1032', // PL
	'matrixrate_matrixrate_1033', // NL
	'matrixrate_matrixrate_1034', // MT
	'matrixrate_matrixrate_1035', // LU
	'matrixrate_matrixrate_1036', // LT
	'matrixrate_matrixrate_1037', // LV
	'matrixrate_matrixrate_1038', // IT
	'matrixrate_matrixrate_1039', // IE
	'matrixrate_matrixrate_1040', // GR
	'matrixrate_matrixrate_1041', // DE
	'matrixrate_matrixrate_1042', // FR
	'matrixrate_matrixrate_1043', // FI
	'matrixrate_matrixrate_1044', // EE
	'matrixrate_matrixrate_1045', // DK
	'matrixrate_matrixrate_1046', // CZ
	'matrixrate_matrixrate_1047', // CY
	'matrixrate_matrixrate_1048', // HR
	'matrixrate_matrixrate_1049', // BG
	'matrixrate_matrixrate_1050', // BE
	'matrixrate_matrixrate_1051', // AT
	'matrixrate_matrixrate_1052', // HU
];

export const SHIPPING_METHOD_CODES = {
	matrixrate_matrixrate_1: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_2: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_5: {
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_mx',
		},
	},
	flatrate_flatrate: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {
			US: 'us_flatrate_delivery_text',
		},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	freeshipping_freeshipping: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {
			US: 'us_freeshipping_delivery_text',
		},
		shipping_notice: {
			default: 'shipping_notice',
			MX: 'shipping_notice_mx',
		},
	},
	ups_11: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_12: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_14: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_54: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_59: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_65: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_01: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_02: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_03: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_07: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	ups_08: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice',
		},
	},
	matrixrate_matrixrate_6: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {
			US: 'matrixrate_matrixrate_6_delivery_text',
		},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_8: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_10: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_82: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_124: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_125: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_126: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_127: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_128: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_129: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_130: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_131: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_132: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_133: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_134: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_135: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_136: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_137: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_138: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_139: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_140: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_141: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_142: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_143: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_144: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_145: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_146: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_147: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_148: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_149: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_150: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_151: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_152: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_153: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_154: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_155: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_156: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_157: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_158: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_159: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_160: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_161: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_163: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_164: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_165: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_166: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_167: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_168: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_169: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_170: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_171: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_172: { // From: US To: Worldwide
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_175: { // From: CA To: Worldwide
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_893: { // PG
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_904: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_905: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_906: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_907: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_908: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_909: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_910: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_911: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_912: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_913: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_914: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_915: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_916: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_917: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_918: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_919: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_920: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_921: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_922: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_923: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_924: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_925: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_926: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_927: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_928: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_929: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_930: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_931: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_932: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_933: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_934: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_935: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_936: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_937: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_938: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_939: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_940: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_941: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_942: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_943: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_944: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_945: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_946: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_947: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_948: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_949: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_950: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_951: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_952: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_953: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_954: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_955: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_956: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_957: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_958: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_959: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_960: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_961: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_962: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_963: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_964: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_965: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_966: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_967: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_968: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_969: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_970: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_971: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_972: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_973: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_974: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_975: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_976: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_977: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_978: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_979: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_980: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_981: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_982: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_983: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_984: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_985: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_986: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_988: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_989: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_990: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_991: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_992: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_993: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_994: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_995: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_996: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_997: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_998: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_999: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1000: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1001: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1002: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1003: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1004: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1005: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1006: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1007: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1008: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1009: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1010: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1011: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1012: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1013: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1014: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1015: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1016: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1017: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1018: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1019: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1020: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1021: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1022: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1023: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1024: {
		carrier: 'USPS',
		image: 'usps.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1029: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1053: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1054: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1055: {
		carrier: 'UPS',
		image: 'ups.png',
		delivery_days: {
			US: 'matrixrate_matrixrate_6_delivery_text',
		},
		shipping_notice: {
			default: 'shipping_notice_us',
		},
	},
	matrixrate_matrixrate_1056: {
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_mx',
		},
	},
	matrixrate_matrixrate_1057: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1058: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1060: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1061: {
		carrier: 'DHL',
		image: 'dhl.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1062: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1063: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1071: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1072: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1073: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	matrixrate_matrixrate_1074: {
		carrier: null,
		image: 'default.png',
		delivery_days: {},
		shipping_notice: {
			default: 'shipping_notice_ca',
		},
	},
	...getShippingMethodsFormat({
		shippingMethods: [...europeShippingMethods, ...europeNewFlatShipping],
	}),
};

<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="align-self-end">
		<li class="h-auto border-0 px-0 d-flex pb-3">
			<div class="form-check">
				<input
					id="policyNProcedures"
					v-model="disclaimer"
					name="policyNProcedures"
					class="form-check-input"
					type="checkbox"
					:value="disclaimer">
				<label
					v-if="[customer, affiliate, agency].includes($user.details().type)"
					class="form-check-label"
					for="policyNProcedures"
					v-html="translate('terms_and_conditions_agreement', {terms_of_services: `<a target='_blank' href='${termsAndConditionsLink}'>${ translate('terms_of_services') }</a>`})" />
				<label
					v-else-if="$user.details().type === distributor"
					class="form-check-label"
					for="policyNProcedures"
					v-html="translate('policies_and_procedures_agreement', {policies_and_procedures: `<a target='_blank' href='${policiesAndProceduresLink}'>${ translate('policies_and_procedures') }</a>`})" />
			</div>
		</li>
	</div>
</template>
<script>
import {
	Purchase as PurchaseMessages,
} from '@/translations';
import { TERMS_AND_CONDITIONS_REDIRECTION } from '@/settings/General';
import {
	distributor, customer, affiliate, agency,
} from '@/settings/Roles';
import CommonMix from '../../mixins/Common';

export default {
	name: 'Disclaimer',
	messages: [PurchaseMessages],
	mixins: [CommonMix],
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
		terms: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			disclaimer: false,
			TERMS_AND_CONDITIONS_REDIRECTION,
			distributor,
			customer,
			affiliate,
			agency,
		};
	},
	computed: {
		termsAndConditionsLink() {
			try {
				return this.terms.terms;
			} catch (error) {
				return TERMS_AND_CONDITIONS_REDIRECTION;
			}
		},
		policiesAndProceduresLink() {
			try {
				return this.terms.mba;
			} catch (error) {
				return TERMS_AND_CONDITIONS_REDIRECTION;
			}
		},
	},
	watch: {
		disclaimer(newVal) {
			this.$emit('change', newVal);
		},
	},
	created() {
		this.disclaimer = this.checked;
	},
};
</script>

<template>
	<div class="row align-items-center">
		<div class="col-12 col-md-4 h-100">
			<div class="row no-gutters align-items-center">
				<div
					:class="stackedLayout ? 'col-6' : 'col-7'"
					class="d-flex p-0 h-100 align-items-center align-content-start">
					<div
						v-if="!stackedLayout"
						class="h-100 mr-2 align-items-center align-content-start">
						<img
							:src="thumbnail"
							class="h-100"
							style="margin-left: -15px; height: 2.325em !important;">
					</div>
					<span class="font-weight-bolder">
						{{ translate(codeName) }}
					</span>
				</div>
				<div
					:class="stackedLayout ? 'col-6' : 'col-5'"
					class="d-flex w-100 align-items-center align-content-start">
					<div
						class="row"
						:class="stackedLayout ? '' : 'no-gutters'">
						<div
							v-if="stackedLayout"
							class="col d-flex mb-1 justify-content-end pr-2">
							<span
								class="h5 mb-0">
								{{ price }}
							</span>
						</div>
						<div
							class="col">
							<quantity-select
								:init-qty="initQty"
								:select-limit="10"
								:max="99"
								include-zero
								:container-class="`w-100 ${stackedLayout ? 'text-right' : ''}`"
								sub-container-class="justify-content-end w-100"
								@change="$emit('change', $event)" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="!stackedLayout"
			class="col-12 col-md-8 h-100 pr-0">
			<div
				class="row no-gutters h-100">
				<div
					class="col-12 d-flex p-0 h-100 align-items-center justify-content-end">
					<span class="h5 mb-0">
						{{ price }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';

export default {
	name: 'SimpleProduct',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		codeName: {
			type: String,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		thumbnail: {
			type: String,
			default: '',
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		price: {
			type: String,
			default: '',
		},
	},
};
</script>

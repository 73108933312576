<template>
	<div class="row pb-2">
		<div
			:class="stackedLayout ? '' : ''"
			class="col-12">
			<div
				class="row"
				:style="stackedLayout ? '' : 'height: 2.325em'">
				<div
					v-if="stackedLayout"
					class="col d-flex p-0 mb-1">
					<div class="my-auto">
						<span class="font-weight-bolder">{{ translate(codeName) }}</span><br>
					</div>
				</div>
				<div
					v-else
					class="col">
					<div
						class="row"
						:style="stackedLayout ? '' : 'height: 2.325em'">
						<div class="col-12 d-flex p-0 h-100 align-items-center">
							<div class="h-100 mr-2">
								<img
									:src="thumbnail"
									class="h-100">
							</div>
							<span class="font-weight-bolder">
								{{ translate(codeName) }}
							</span>
						</div>
					</div>
				</div>
				<div
					class="col">
					<div
						class="row"
						:class="stackedLayout ? 'pr-2' : ''"
						:style="stackedLayout ? '' : 'height: 2.325em'">
						<div class="col-12 d-flex p-0 h-100 align-items-center justify-content-end">
							<span class="h5 mb-0 text-right">
								{{ getDisplayPrice() }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div
				:class="stackedLayout ? 'mb-0' : ''"
				class="row">
				<div class="col-12 pt-1">
					<div class="row">
						<div
							:class="stackedLayout ? 'pl-2' : ''"
							class="col-12">
							<slot />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Products, Purchase } from '@/translations';

export default {
	name: 'ConfigurableProduct',
	messages: [Products, Purchase],
	props: {
		product: {
			type: Object,
			default: () => ({}),
		},
		codeName: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			default: '',
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		getDisplayPrice() {
			const { children, price, price_amount: priceAmount } = this.product;
			// Display a range for parent products that have children with different values.
			if (Object.keys(children).length > 0 && priceAmount === 0) {
				const prices = Object.values(children)
					.map((variant) => variant.price_amount)
					.sort((a, b) => a - b);
				return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')} USD` : price;
			}
			return price;
		},
	},
};
</script>

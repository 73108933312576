<template>
	<div class="row mb-2 no-gutters">
		<div class="col px-4 py-3">
			<div class="h5">
				<span v-if="walletSplit">{{ translate('cashondelivery') }} + </span> {{ translate(paymentMethodName) }}
			</div>
			<template v-if="billing.country_code !== '' && !loadingState">
				<b-row>
					<b-col>
						<p class="mb-0">
							<b>{{ billing.first_name }} {{ billing.last_name }}</b>
						</p>
						<p class="mb-0">
							{{ billing.address }}
						</p>
						<p class="mb-0">
							{{ billing.address2 }}
						</p>
						<p class="mb-0">
							{{ billing.city }}, {{ billing.region_name }}, {{ billing.postcode }}
						</p>
						<p class="mb-0">
							{{ translate(billing.country_code.toLowerCase()) }}
						</p>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col
						:order="Number(walletSplit)"
						:class="{ 'col-auto': !walletSplit }">
						<p class="mb-0">
							<b v-if="walletSplit">{{ translate(paymentMethodName) }}</b>
						</p>
						<p
							v-if="isCreditCard"
							class="mb-0 d-inline">
							<img
								:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
								class="my-auto mr-1"
								alt="Card Logo"
								style="width:45px; height:30px;">
							<span class="text-muted"> ****{{ lastDigits.length > 0 ? lastDigits : '' }}</span>
						</p>
						<p
							:class="{ 'ml-2': isCreditCard }"
							class="mb-0 d-inline">
							<b v-if="walletSplit">{{ cartTotal - walletSplitAmount | currency('usd', translate) }}</b>
						</p>
					</b-col>
					<b-col
						v-if="canSplitWithWallet"
						:order="Number(!walletSplit)"
						:style="!walletSplit && !isCreditCard ? 'margin-top: -35px' : ''">
						<div
							:class="{ 'justify-content-end': !walletSplit, 'justify-content-start': walletSplit }"
							class="d-flex align-items-center h-100">
							<span
								v-if="!walletSplit"
								class="text-primary btn-link pointer"
								@click="$emit('walletSplit')">
								{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate('other_method') }) }}
							</span>
							<b v-else>
								{{ translate('cashondelivery') }}
								<br>
								{{ walletSplitAmount | currency('usd', translate) }}
							</b>
						</div>
					</b-col>
				</b-row>
			</template>
			<div
				v-else
				class="row mb-2">
				<div
					class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
					<div class="col-12">
						<div class="h2">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							<h4 class="mt-3">
								{{ translate('loading') }}
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AVAILABLECC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import {
	Countries as CountriesMessages,
	PaymentMethods as PaymentMethodsMessages,
} from '@/translations';
import Country from '@/util/Country';
import CreditCard from '@/util/CreditCard';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import { currency } from '@/config/Filters';
import { CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';

export default {
	name: 'PaymentOverview',
	messages: [CountriesMessages, PaymentMethodsMessages],
	filters: {
		currency,
	},
	mixins: [Addresses, CommonMix],
	props: {
		cartTotal: {
			type: Number,
			default: 0,
		},
		canSplitWithWallet: {
			type: Boolean,
			default: false,
		},
		billingAddressId: {
			type: Number,
			default: 0,
		},
		paymentMethodName: {
			type: String,
			default: '',
		},
		cardType: {
			type: String,
			default: '',
		},
		lastDigits: {
			type: String,
			default: '',
		},
		ccAddress: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			creditCard: new CreditCard(),
			stateData: new Country(),
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_name: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
				wallet_split: false,
			},
		};
	},
	computed: {
		walletSplit() {
			return !!this.paymentMethod.wallet_split;
		},
		walletSplitAmount() {
			return Number(this.paymentMethod.wallet_split_amount);
		},
		loadingState() {
			return this.stateData.data.loading;
		},
		cardLogo() {
			if (this.cardType.length > 0 && AVAILABLECC.includes(this.cardType)) {
				return this.cardType;
			}
			return 'cc';
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		isCreditCard() {
			return CREDIT_CARD_FORM_METHODS.includes(this.paymentMethodName);
		},
	},
	watch: {
		billingAddressId: {
			handler(id) {
				if (id) {
					this.billing.address_id = id;
					this.refreshAddressBook().then(() => {
						const foundAddress = this.addressData.find((address) => +address.id === +this.billing.address_id);

						this.billing = {
							...this.billing,
							first_name: foundAddress.attributes.first_name,
							last_name: foundAddress.attributes.last_name,
							address: foundAddress.attributes.address,
							address2: foundAddress.attributes.address2,
							postcode: foundAddress.attributes.postcode,
							city: foundAddress.attributes.city,
							country_code: foundAddress.attributes.country.code.toLowerCase(),
						};

						this.stateData.getState(foundAddress.attributes.region.id).then((state) => {
							this.billing.region_name = state.attributes.name;
						});
					});
				}
			},
			immediate: true,
		},
		ccAddress: {
			handler(address) {
				if (Object.keys(address).length > 0) {
					this.useCreditCardAddress(address);
				}
			},
			immediate: true,
		},
	},
	methods: {
		useCreditCardAddress(ccAddress) {
			this.billing = {
				...this.billing,
				first_name: ccAddress.firstname,
				last_name: ccAddress.lastname,
				address: ccAddress.street[0],
				address2: ccAddress.street[1],
				postcode: ccAddress.postcode,
				city: ccAddress.city,
				country_code: ccAddress.country_id,
			};

			this.stateData.getState(ccAddress.region.region_id).then((state) => {
				this.billing.region_name = state.attributes.name;
			});
		},
	},
};
</script>
